import { render, staticRenderFns } from "./Dispatch.vue?vue&type=template&id=44ef42bf&scoped=true"
import script from "./Dispatch.vue?vue&type=script&lang=js"
export * from "./Dispatch.vue?vue&type=script&lang=js"
import style0 from "./Dispatch.vue?vue&type=style&index=0&id=44ef42bf&prod&lang=scss"
import style1 from "./Dispatch.vue?vue&type=style&index=1&id=44ef42bf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ef42bf",
  null
  
)

export default component.exports